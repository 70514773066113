/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Context } from '@nuxt/types';
import cookieNames from '~/enums/cookieNameEnum';
import { hasAuthorizationError } from '~/helpers/util';

export default ({ app, route, redirect }: Context) => {
  const { fullPath } = route;
  const PAYLOAD_MEDIA_URL = 'https://gemini.payloadcms.app/2e3af13c-f6ef-4e91-804b-168b62f96707/media';

  if (fullPath.includes('/public/userfiles/files/Istruzioni_di_montaggio_linea_maniglieCOLFERT_maniglie_VELA_KATA.pdf')) {
    redirect(301, `${PAYLOAD_MEDIA_URL}/istruzioni_di_montaggio_maniglie_kitS_VELAeKATA.pdf`);
  }
  if (fullPath.includes('/public/userfiles/files/Istruzioni_di_montaggio_linea_maniglieCOLFERT_maniglioni_VELA_KATA.pdf')) {
    redirect(301, `${PAYLOAD_MEDIA_URL}/istruzioni_di_montaggio_maniglioniVELAeKATA.pdf`);
  }
  if (fullPath.includes('il-catalogo')) {
    redirect(301, '/');
  }
  if (hasAuthorizationError(app.$cookies.get(cookieNames.customerCookieName))) {
    app.$cookies.remove(cookieNames.customerCookieName);
    app.$cookies.remove(cookieNames.cartCookieName);
    app.$cookies.remove(cookieNames.segmentsCookieName);
    app.$cookies.set(cookieNames.messageCookieName, {
      message: app.i18n.t('You are not authorized, please log in.'),
      type: 'warning',
      icon: null,
      persist: false,
      title: null,
    });
  }
};
